<template>

<div class="screen" :class="{'is-guest': !ready || !$context}">

	<com-menu v-if="ready && $context" />
	<com-content :is-active="hasContent" v-if="!$loggedin || !$context || ready || $route.name === 'Dashboard'" />
	<com-preview :is-managing="hasContent" v-if="ready && $context" />

	<app-icon icon="loading" v-if="$loggedin && !ready" class="screen-loading" />

</div>

</template>

<script>

export default {

	components: {
		'com-menu': () => import('./screen/Menu'),
		'com-content': () => import('./screen/Content'),
		'com-preview': () => import('./screen/Preview')
	},

	computed: {

		ready: function() {

			return this.$store.getters['ready']

		},

		hasContent: function() {

			return true

		}

	}

}

</script>

<style scoped>

.screen {
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #8BC6EC;
	background-image: linear-gradient(135deg, #5480D3 0%, #3256A7 100%);
}

.screen-loading {
	color: #fff;
	font-size: 32px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

</style>
